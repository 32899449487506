<template>
  <v-container id="customers" fluid tag="section">
    <loader :active="loaderActive" />
    <v-row>
      <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="primary"
          icon="mdi-account-multiple"
          title="Total"
          :value="
            responsePayload.total ? responsePayload.total.toString() : '0'
          "
        />
      </v-col>

      <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="success"
          icon="mdi-account-check"
          title="Active"
          :value="
            responsePayload.total ? responsePayload.total.toString() : '0'
          "
        />
      </v-col>

      <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="orange"
          icon="mdi-account-clock"
          title="Inactive"
          value="0"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="red"
          icon="mdi-account-off"
          title="Suspended"
          value="0"
        />
      </v-col>
      <v-col cols="12" md="12">
        <v-row rows="12" align="end" justify="end">
          <div class="my-2">
            <template>
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    elevation="9"
                    color="primary"
                    x-large
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon left>mdi-account-plus</v-icon>
                    Create Customer
                  </v-btn>
                  <v-btn
                    elevation="9"
                    color="indigo"
                    x-large
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon left>mdi-cloud-upload</v-icon>
                    Upload customers
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItem.lastName"
                            label="Last name"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItem.firstName"
                            label="First name"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItem.identificationNumber"
                            label="Identification number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItem.dob"
                            label="DOB"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="close"> Cancel </v-btn>
                    <v-btn color="primary" text @click="save"> Save </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >Are you sure you want to delete this
                    customer?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-btn color="primary" text @click="deleteItemConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
          </div>
        </v-row>
      </v-col>
      <v-col cols="12" md="12">
        <base-material-card color="primary" class="px-5 py-3">
          <template v-slot:heading>
            <div class="display-2 font-weight-dark lightgray--text">
              Customer List
              <button class="float-right">Download</button>
              <v-spacer />
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                color="lightgray"
              />
            </div>

            <div class="float-right white--text">
              <v-switch
                v-model="singleExpand"
                color="#ffffff"
                label="Single Expand"
                class="mt-2"
              ></v-switch>
            </div>
          </template>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              :loading="loaderActive"
              :single-expand="singleExpand"
              :expanded.sync="expanded"
              item-key="id"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              show-expand
              class="elevation-1"
              @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
              @dblclick:row="editItem(item)"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  color="green"
                  medium
                  class="mr-2"
                  @click="editItem(item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon color="red" medium @click="deleteItem(item)">
                  mdi-delete
                </v-icon>
              </template>
              <template v-slot:no-data>
                <v-btn color="primary" @click="initialize"> Reset </v-btn>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <v-spacer />
                  More info about {{ item.id }}
                  <v-spacer />
                  More info about {{ item.firstName }}
                  <v-spacer>
                    More info about {{ item.updatedBy }}
                    <v-spacer />
                    More info about {{ item.createdAt }}
                    <v-spacer />
                    Created at {{ item.createdAt }}
                  </v-spacer>
                  <v-spacer />
                </td>
              </template>
            </v-data-table>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import store from "../../../../store";
import loaderMixin from "../../../../mixins/loader";
export default {
  mounted() {
    document.title = "Customers | Kredwise";
  },
  components: {
    Loader: () => import("../../../../components/Loader"),
  },
  mixins: [loaderMixin],
  data: () => ({
    responsePayload: {},
    dialog: false,
    dialogDelete: false,
    sortBy: "id",
    sortDesc: true,
    search: "",
    expanded: [],
    singleExpand: false,
    headers: [
      {
        sortable: false,
        text: "Created At",
        value: "createdAt",
        align: "left",
      },
      {
        sortable: true,
        text: "First Name",
        value: "firstName",
      },
      {
        sortable: true,
        text: "Last Name",
        value: "lastName",
      },
      {
        sortable: true,
        text: "Phone Number",
        value: "phonenumbers[0].phone",
      },
      {
        sortable: false,
        text: "Company",
        value: "user.company.name",
      },
      { text: "Actions", value: "actions", sortable: false, align: "right" },
      { text: "", value: "data-table-expand" },
    ],
    items: [],
    editedIndex: -1,
    editedItem: {
      firstName: "",
      lastName: "",
      dob: "",
      identificationNumber: "",
    },
    defaultItem: {
      firstName: "",
      lastName: "",
      dob: "",
      identificationNumber: "",
    },
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Create Customer" : "Edit Customer";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      this.showLoader();
      await this.$store.dispatch("customer/fetchCustomers"),
        (this.items = store.state.customer.customers.data);
      this.responsePayload = store.state.customer.customers;
      this.hideLoader();
    },

    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.items.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.items[this.editedIndex], this.editedItem);
      } else {
        this.items.push(this.editedItem);
      }
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped></style>
